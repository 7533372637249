import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';
import './rich-text-css-settings.css';
import {
  CustomToolbarRichText,
  fontFamilyOptions,
  modules,
} from '@/features/editor/widgets/custom-widget/inputs/typography/custom-toolbar-rich-text';
import { EditorContext } from '@/features/editor/context/editor-context';
import { DeviceType } from '@/utils/definitions';
import { HistoryOpts } from '@/features/editor/widgets/custom-widget/inputs/shared/input-type';

// RETURN THIS CODE FOR STYLES

// import { maybe } from '@/features/details/utils';

// const VALUE_KEY = `defaultAttributes`;

const ReactQuill =
  typeof window === `object` ? require(`react-quill`) : () => false;
export interface RichTextEditorProps {
  defaultValue: string;
  onChange: (ev: any) => void;
  ref: any;
  spec: any;
  onValuesChanged: (
    key: string,
    value: any,
    device?: DeviceType,
    historyOpts?: HistoryOpts,
  ) => void;
}
export function RichTextEditor({
  defaultValue,
  ref,
  // RETURN THIS CODE FOR STYLES
  // spec,
  // onValuesChanged,
  onChange,
}: RichTextEditorProps) {
  const [updatedFonts, setUpdatedFonts] = useState([]);
  const [isRendered, setIsRendered] = useState(false);
  const [colorValue, setColorValue] = useState(`black`);
  const [fontSizeValue, setFontSizeValue] = useState(`13`);
  const [letterSpacingValue, setLetterSpacingValue] = useState(`0`);
  const [lineHeightValue, setLineHeightValue] = useState(`unset`);

  const editorRef = useRef(null);

  const {
    devicePreview: {
      editorState: { installedFonts },
    },
  } = useContext(EditorContext);

  useEffect(() => {
    const defaultFonts = fontFamilyOptions.filter(
      (font) =>
        !installedFonts.some(
          (option) => option.toLowerCase() === font.toLowerCase(),
        ),
    );
    const updated = [...installedFonts, ...defaultFonts];
    setUpdatedFonts(updated);
  }, [installedFonts]);

  useEffect(() => {
    if (editorRef.current) {
      const quill = editorRef.current.getEditor();

      const handleSelectionChange = (range) => {
        if (range) {
          const format = quill.getFormat(range.index);
          const letterSpacing = format.letterSpacing || `0px`;
          const fontSize = format.size || `13px`;
          const color = format.color || `black`;
          const lineHeight = format.lineHeight || `unset`;

          setLineHeightValue(lineHeight.split(`px`)[0]);
          setLetterSpacingValue(letterSpacing.split(`px`)[0]);
          setFontSizeValue(fontSize.split(`px`)[0]);
          setColorValue(color);
        }
      };
      const delayedSelectionChange = (range: any) => {
        setTimeout(() => handleSelectionChange(range), 100);
      };
      quill.on(`selection-change`, delayedSelectionChange);

      return () => {
        quill.off(`selection-change`, handleSelectionChange);
      };
    }
    return null;
  }, [isRendered]);

  useEffect(() => {
    const styleElement = document.createElement(`style`);
    document.head.appendChild(styleElement);
    updatedFonts.forEach((font) => {
      styleElement.sheet.insertRule(
        `#toolbar .ql-font span[data-label='${font}']::before { font-family: ${font}; }`,
        styleElement.sheet.cssRules.length,
      );
    });

    // RETURN THIS CODE FOR STYLES

    // if (editorRef?.current) {
    //   const editor = editorRef.current.getEditor();
    //   if (editor) {
    //     const delta = editor.getContents();
    //     const attributes =
    //       maybe(() => spec?.values?.defaultAttributes) || usualAttributes;
    //     console.log(`ppp PROBLEM HERE`, attributes);
    //     const op = delta.ops.find(
    //       (el: { insert: string }) => !!el.insert && el.insert !== `/n`,
    //     );
    //     if (op) {
    //       op.attributes = {
    //         ...op.attributes,
    //         ...attributes,
    //       };
    //     }
    //     editor.setContents(delta);
    //     Object.keys(attributes).forEach((key) => {
    //       const value = attributes[key];
    //       editor.format(key, value);
    //     });
    //     if (!!updatedFonts[0] && !attributes.font) {
    //       editor.format(`font`, updatedFonts[0]);
    //     }
    //   }
    // }

    return () => {
      document.head.removeChild(styleElement);
    };
  }, [isRendered, updatedFonts]);

  function onQuillChange(val) {
    onChange(encodeURIComponent(val));
    // RETURN THIS CODE FOR STYLES

    // if (editorRef?.current) {
    //   const editor = editorRef.current.getEditor();
    //   if (editor) {
    //     // const delta = editor.getContents();
    //     // const { attributes } = delta.ops[0];
    //
    //     const format = editor.getFormat();
    //     console.log(`ppp SAVING HERE`, format);
    //     onValuesChanged(VALUE_KEY, format);
    //   }
    // }
  }

  return (
    updatedFonts.length > 0 && (
      <Wrapper ref={ref} className="text-editor">
        <CustomToolbarRichText
          isRendered={isRendered}
          setIsRendered={setIsRendered}
          updatedFonts={updatedFonts}
          setColorValue={setColorValue}
          colorValue={colorValue}
          fontSizeValue={fontSizeValue}
          setFontSizeValue={setFontSizeValue}
          letterSpacingValue={letterSpacingValue}
          setLetterSpacingValue={setLetterSpacingValue}
          lineHeightValue={lineHeightValue}
          setLineHeightValue={setLineHeightValue}
          editorRef={editorRef}
        />
        {isRendered && (
          <ReactQuill
            value={decodeURIComponent(defaultValue)}
            ref={editorRef}
            onChange={(val: string) => onQuillChange(val)}
            modules={modules}
            placeholder="Change me"
          />
        )}
      </Wrapper>
    )
  );
}

const Wrapper = styled.div`
  background: white;
  margin-bottom: 2rem;
  border-radius: 10px !important;
`;
// RETURN THIS CODE FOR STYLES

// const usualAttributes = {
//   align: `center`,
//   font: null,
//   color: `#111111`,
//   fontSize: `24px`,
//   fontWeight: `500`,
// };
